import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../../../../../utils/Context';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import '../../../../../GetQuote/VehicleDetails/VehicleDetails';
import VIN from '../../../../../../../../src/components/pages/GetQuote/VehicleDetails/Section/VIN/VIN';
import ManualDetails from '../../../../../../../../src/components/pages/GetQuote/VehicleDetails/Section/ManualDetails/ManualDetails';
import NonMadatoryVehicleDetails from '../../../../../../../../src/components/pages/GetQuote/VehicleDetails/Section/NonMadatoryVehicleDetails/NonMandatoryVehicleDetails';
import DOTInfo from '../../../../../../../../src/components/pages/GetQuote/VehicleDetails/Section/DOTInfo/DOTInfo';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Arrowsvg from '../../../../../../../assets/images/Form/Arrow.svg';
import { GET_VEHICLE_DETAILS } from '../../../../../../../api/endpoint';
import { getRequest } from '../../../../../../../api/api';
import { useNavigate } from 'react-router-dom';
import { GetGuestUserToken } from '../../../../../../../../src/components/pages/GetQuote/guestUserTokenUtils';

const VehicleDetails = ({ current, setCurrent, saveProfile }) => {
  const location = useLocation();
  const wasRedirected = location.state && location.state.fromRedirected;
  const navigate = useNavigate();
  const isNewVehicleRoute = location.pathname.includes('/new-vehicle');

  const {
    vehicles,
    setVehicles,
    profileObject,
    setProfileObject,
    setUserEmail,
    setUserId,
    setDisableState,
  } = useContext(Context);
  const [showSection1, setShowSection1] = useState(true);
  const [showSection2, setShowSection2] = useState(false);
  const [showDotInfo, setshowDotInfo] = useState(false);
  const [activeTab, setActiveTab] = useState('second');
  const [editVehicleIndex, setEditVehicleIndex] = useState(null);
  const [unitType, setUnitType] = useState('powerUnit');

  const [cargo, setCargo] = useState(null);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    getVehicleDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(!isNewVehicleRoute){
    const guestUserToken = GetGuestUserToken();
    if (profileObject && guestUserToken === null) {
      console.log("Redirecting to /new-vehicle");
      navigate('/account/vehicle-info/new-vehicle', { replace: true });
    } else if (profileObject && profileObject?.is_verified) {
      console.log("Redirecting to /quote/payment");
      window.scrollTo(0, 0);
      navigate('/quote/payment');  // Put this when finish -> navigate('/quote/payment');  --> //to test this /account/vehicle-info/new-vehicle
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }}, [profileObject]);

  const getVehicleDetails = async () => {
    try {
      console.log("Fetching vehicle details...");
      const response = await getRequest(GET_VEHICLE_DETAILS);
      console.log("API Response:", response);
  
      if (response.result) {
        setUserEmail(response.result.email);
        setUserId(response.result.id);
  
        if (response.result.profiles?.length > 0) {
          console.log("Profiles found:", response.result.profiles);
          const firstProfile = response.result.profiles[0];
  
          setProfileObject(firstProfile);
  
          const vehicleDetails = firstProfile.vehicle_details || [];
          console.log("Extracted vehicle details:", vehicleDetails);
          setVehicles(vehicleDetails);
        } else {
          console.log("No profiles found. Creating empty profile object.");
          setProfileObject({}); // Se establece un objeto vacío en lugar de undefined
          setVehicles([]); // Aseguramos que vehicles no sea undefined
        }
      } else {
        console.log("Invalid API response format");
      }
    } catch (error) {
      console.error("API error fetching vehicle details:", error);
    }
  };

  const addNewVehicle = (unitType, vehicleIndex) => {
    setDisableState(false);
    setUnitType(unitType);
    setVehicles((prevVehicles) => [...prevVehicles, {}]);
    setShowSection1(true);
    setShowSection2(false);
    setshowDotInfo(false);
    setActiveTab('second');
    setEditVehicleIndex(null);
  };

  return (
    <>
      {Array.isArray(vehicles) && vehicles.length > 0 ? (
        vehicles.map((vehicle, vehicleIndex) => {
          const isLatestVehicle = vehicleIndex === vehicles.length - 1;
  
          return (
            <Row className={`mb-3`} key={vehicleIndex}>
              <Col
                lg={8}
                className={`${
                  (isLatestVehicle && editVehicleIndex === null) ||
                  editVehicleIndex === vehicleIndex
                    ? ''
                    : 'd-none'
                }`}
              >
                <div className="current-container mb-3 mb-md-5">
                  {wasRedirected && (
                    <Link
                      to={'/account/vehicle-info'}
                      className="mt-5 text-decoration-none"
                    >
                      <span className="float-start back-btn-style">
                        <img src={Arrowsvg} className="pe-2" alt="" />
                        <span className="mt-5 text-blue">
                          Back to Vehicle Info
                        </span>
                      </span>
                    </Link>
                  )}
                  {showSection1 && (
                    <div className="">
                      <h3 className="fw-bold">Please add a new Vehicle</h3>
                      <Container>
                        <Tab.Container
                          defaultActiveKey={
                            vehicle.vin_number && vehicle.vin_number.length > 0
                              ? 'first'
                              : 'second'
                          }
                        >
                          <Row>
                            <Nav variant="pills">
                              <Nav.Item className="tab-button fw-bold fs-6">
                                <Nav.Link eventKey="second" style={{ paddingLeft: '10px' }}>
                                  Without VIN
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="tab-button fw-bold fs-6">
                                <Nav.Link eventKey="first" style={{ paddingLeft: '10px' }}>
                                  With VIN
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Row>
                          <Row>
                            <Tab.Content>
                              <Tab.Pane eventKey="first">
                                <VIN
                                  setShowSection2={setShowSection2}
                                  setShowSection1={setShowSection1}
                                  activeTab={activeTab}
                                  vehicleIndex={vehicleIndex}
                                  setCargo={setCargo}
                                  saveProfile={saveProfile}
                                  profileObject={profileObject}
                                />
                              </Tab.Pane>
                              <Tab.Pane eventKey="second">
                                <ManualDetails
                                  setShowSection2={setShowSection2}
                                  setShowSection1={setShowSection1}
                                  activeTab={activeTab}
                                  vehicleIndex={vehicleIndex}
                                  profile={profile}
                                  setProfile={setProfile}
                                  cargo={cargo}
                                  setCargo={setCargo}
                                  saveProfile={saveProfile}
                                  unitType={unitType}
                                  profileObject={profileObject}
                                />
                              </Tab.Pane>
                            </Tab.Content>
                          </Row>
                        </Tab.Container>
                      </Container>
                    </div>
                  )}
                  {showSection2 && (
                    <div className="">
                      <h3 className="fw-bold">Tell us more about your Vehicle...?</h3>
                      <Container className="plr-0-sm">
                        <NonMadatoryVehicleDetails
                          setShowSection2={setShowSection2}
                          setshowDotInfo={setshowDotInfo}
                          setShowSection1={setShowSection1}
                          vehicleIndex={vehicleIndex}
                          saveProfile={saveProfile}
                        />
                      </Container>
                    </div>
                  )}
                  {showDotInfo && (
                    <div>
                      <h3 className="fw-bold">Select Vehicle DOT Information</h3>
                      <Container>
                        <DOTInfo
                          setShowSection2={setShowSection2}
                          setshowDotInfo={setshowDotInfo}
                          vehicleIndex={vehicleIndex}
                          addNewVehicle={addNewVehicle}
                          saveProfile={saveProfile}
                        />
                      </Container>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          );
        })
      ) : (
        <Row className="mb-3">
          <Col lg={8}>
            <div className="current-container mb-3 mb-md-5">
              <h3 className="fw-bold">Please add a new Vehicle</h3>
              <Container>
                <Tab.Container defaultActiveKey="second">
                  <Row>
                    <Nav variant="pills">
                      <Nav.Item className="tab-button fw-bold fs-6">
                        <Nav.Link eventKey="second" style={{ paddingLeft: '10px' }}>
                          Without VIN
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="tab-button fw-bold fs-6">
                        <Nav.Link eventKey="first" style={{ paddingLeft: '10px' }}>
                          With VIN
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Row>
                  <Row>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <VIN
                          setShowSection2={setShowSection2}
                          setShowSection1={setShowSection1}
                          activeTab={activeTab}
                          vehicleIndex={0}
                          setCargo={setCargo}
                          saveProfile={saveProfile}
                          profileObject={profileObject}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <ManualDetails
                          setShowSection2={setShowSection2}
                          setShowSection1={setShowSection1}
                          activeTab={activeTab}
                          vehicleIndex={0}
                          profile={profile}
                          setProfile={setProfile}
                          cargo={cargo}
                          setCargo={setCargo}
                          saveProfile={saveProfile}
                          unitType={unitType}
                          profileObject={profileObject}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Row>
                </Tab.Container>
              </Container>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
}  

export default VehicleDetails;
