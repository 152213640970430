const apiEndpoint = 'https://staging-api.silomembers.com/';

/* Quote APIs */
export const CREATE_PROFILE = `${apiEndpoint}api/v1/profiles.json`;
export const UPDATE_PROFILE = `${apiEndpoint}api/v1/profiles`;

export const GET_VEHICLE_DETAILS = `${apiEndpoint}api/v1/users/program.json`;
export const GET_VEHICLE_TYPE_API = `${apiEndpoint}api/v1/vehicle_types`;
export const GET_BODY_TYPE_API = `${apiEndpoint}api/v1/vehicle_body_types`;
export const GET_MAKE_API = `${apiEndpoint}api/v1/vehicle_makes`;
export const GET_VEHICLE_MODEL_API = `${apiEndpoint}api/v1/vehicle_models`;
export const GET_VEHICLE_SAFETY_API = `${apiEndpoint}api/v1/vehicle_safety_systems`;
export const GET_ELD_PROVIDER_API = `${apiEndpoint}api/v1/eld_providers`;
export const GET_CARGO_COMMODITIES_API = `${apiEndpoint}api/v1/cargo_commodities`;
export const GET_DOT_INFORMATION_API = `${apiEndpoint}api/v1/dot_informations`;
export const GET_SUFFIX_LIST = `${apiEndpoint}api/v1/suffix_names`;
export const GET_LIENHOLDER_LIST = `${apiEndpoint}api/v1/lienholders`;

export const VALIDATE_VIN = `${apiEndpoint}api/v1/vin_numbers`;
export const GET_POLICY_DETAILS = `${apiEndpoint}api/v1/policy_details`;
export const POST_USER_FORM = `${apiEndpoint}api/v1/home_page`;
export const GET_PD_PREMIUM = `${apiEndpoint}api/v1/policy_details/calculate_pd_premium.json`;
export const GET_CG_PREMIUM = `${apiEndpoint}api/v1/policy_details/calculate_cg_premium.json`;
export const SAVE_USER = `${apiEndpoint}/api/v1/users.json`;
export const CREATE_CHILD_USER = `${apiEndpoint}api/v1/users/create_child.json`;
export const VERIFY_OTP = `${apiEndpoint}api/v1/users/verify_otp.json`;
export const RESEND_OTP = `${apiEndpoint}api/v1/users/resend_otp.json`;
export const CHECK_EMAIL = `${apiEndpoint}api/v1/users/check_email.json`;
export const VERIFY_VIN = `${apiEndpoint}api/v1/profiles/1/verify_vin_number`;
export const DELETE_ATTACHMENTS = (profileId) =>
  `${apiEndpoint}api/v1/profiles/${profileId}/delete_attachments`;
export const GET_AGENCY_PRODUCER_ID = `${apiEndpoint}api/v1/profiles/1/get_producer_agency_id`;

/* DOCUSIGN API's */
export const REGENERATE_DOCUSIGN_AGREEMENT = `${apiEndpoint}api/v1/users/regenerate_focused_url.json`;
export const REGENERATE_AGREEMENT_MAIL = `${apiEndpoint}/api/v1/users/resend_agreement_mail`;

/* PAYMENT HANDLING API's */
export const PAYMENT_CHECKOUT = `${apiEndpoint}api/v1/checkouts`;
export const PAYMENT_SUCCESS = `${apiEndpoint}api/v1/checkouts/success.json`;
export const PAYMENT_FAILED = `${apiEndpoint}api/v1/checkouts/failure.json`;

/* AI related APIs */
export const VALUE_MOCK =
  'https://run.mocky.io/v3/85a9c1c0-2c1d-412c-8eba-4e17f7350f17';
export const VALUE_AI = `${apiEndpoint}api/v1/chat/send_message`;

/*Logged In Area APIs */
export const VALUE_MOCK_AI =
  'https://run.mocky.io/v3/2fc204ab-aa1f-402b-8bab-ebdcd4336c83';
export const VEHICLE_INFO_MOCK =
  'https://run.mocky.io/v3/ed7d5847-bd78-4064-9164-ebc25ba7265e';
export const DRIVER_INFO_MOCK =
  'https://run.mocky.io/v3/7b00f6eb-6955-435f-bf72-9cdc49224560';
export const GET_STATE_LIST = `${apiEndpoint}api/v1/states`;
export const GET_CITY_LIST = `${apiEndpoint}api/v1/cities`;
export const POST_LOGIN = `${apiEndpoint}api/v1/login`;
export const PASSWORDS = `${apiEndpoint}api/v1/passwords`;
export const RESET_PASSWORD = `${apiEndpoint}api/v1/resetpassword`;
export const DELETE_LOGOUT_USER = `${apiEndpoint}api/v1/logout`;
